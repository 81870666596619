.early_access_main_cointainer {
  position: relative;
  height: 100vh;
  width: 100%;
  background-image: url(https://uploads-ssl.webflow.com/629f6604aab28d4f233a106f/629f6604aab28dbac63a112e_bg-blur-4-web3-webflow-ecommerce-template.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.early_access_container_center {
  width: 80%;
  margin: 0 auto;
}
