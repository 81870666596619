.spotline__brand {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.spotline__brand div {
  flex: 1;
  max-width: 150px;
  min-width: 120px;
  margin: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;
}
.spotline__brand p {
  color: #787f98;
  font-weight: 700;
  font-size: 1.3rem;
}
